import React, { useState } from "react";
import seven from '../assets/Seven.png';
import question from '../assets/QuestionIcon.png';

export default function TervAssuredBox() {

  const [question1 , setQuestion1] = useState(false);
  const [question2 , setQuestion2] = useState(false);

  return (
    <>
      <section
        id="job-guarantee"
        className="z-[999] flex justify-center py-10 sm:py-12"
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          scrollMarginTop: "100px",
          zIndex: 999,
          display: "flex",
          justifyContent: "center",
          paddingBottom: "3rem",
          paddingTop: "3rem",
        }}
      >
        <div
          className="container relative z-[999] mx-4 max-w-[850px] rounded-[30px] bg-v5-green-500 py-8 px-4 text-white md:py-12 md:px-8"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            width: "100%",
            position: "relative",
            zIndex: 999,
            marginLeft: "1rem",
            marginRight: "1rem",
            maxWidth: "850px",
            borderRadius: "30px",
            backgroundColor: "#5E35B1",
            color: "rgb(255 255 255/1)",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingBottom: "3rem",
            paddingTop: "3rem",
          }}
        >
          <h2
            className="mb-5 text-center font-manrope text-[28px] font-black text-neutral-200 md:mb-10 md:text-4xl"
            style={{
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              overflowWrap: "break-word",
              margin: "0px",
              textAlign: "center",
              fontFamily: "Manrope, sans-serif",
              fontWeight: 900,
              color: "rgb(229 229 229/1)",
              marginBottom: "2.5rem",
              fontSize: "2.25rem",
              lineHeight: "2.5rem",
            }}
          >
            Terv’s Assured Referrals Explained
          </h2>
          <p
            className="mb-4 text-center text-sm leading-6 text-v5-neutral-300 md:mb-8 md:text-base md:leading-8"
            style={{
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              overflowWrap: "break-word",
              margin: "0px",
              textAlign: "center",
              color: "rgb(185 207 202/1)",
              marginBottom: "2rem",
              fontSize: "1rem",
              lineHeight: "2rem",
            }}
          >
            Terv is a partner in our learners’ career transformation and as part
            of our offering, our learners are eligible for assured referrals and
            job assistance based on their profile.
            <br
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            />{" "}
            <br
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            />{" "}
            We believe that we deserve a portion of the fee only after a
            successful outcome for our learners. Thus, if you are eligible for
            assured referrals & job assistance we will keep aside a component
            called as “
            <i
              className="font-bold"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                fontWeight: 700,
              }}
            >
              Success Fee
            </i>
            ” for post placement. This fee is waived off if the learner is not
            getting a placement within 6 months of{" "}
            <i
              className="font-bold"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                fontWeight: 700,
              }}
            >
              placement eligibility date
            </i>
            .
          </p>
          <div
            className="flex w-full flex-wrap items-stretch justify-center gap-y-4 gap-x-4 text-v5-neutral-200"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              alignItems: "stretch",
              justifyContent: "center",
              columnGap: "1rem",
              rowGap: "1rem",
              color: "rgb(242 251 247/1)",
            }}
          >
            <div
              className="w-full max-w-[800px]"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
                maxWidth: "800px",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[10px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  borderRadius: "10px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  backgroundColor: "#242145",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "#242145",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                >
                  <div
                    className="MuiAccordionSummary-content jss14 Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                    onClick={() => setQuestion1(!question1)}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-center gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "center",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "300px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src={seven}
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="text-base transition-all sm:w-full"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              fontSize: "1rem",
                              lineHeight: "1.5rem",
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              Who is eligible?
                            </div>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: "rotate(180deg)",
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "283ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root rounded-b-[30px] bg-v5-green-700 pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="md:pl-[20px]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "20px",
                            }}
                          >
                            <p
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                overflowWrap: "break-word",
                                margin: "0px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                <ul
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    listStyle: "none",
                                    padding: "0px",
                                    listStyleType: "disc",
                                    margin: "1em 0px",
                                    display: "block",
                                    paddingLeft: "40px",
                                  }}
                                >
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    Every admitted candidate can avail Terv
                                    Exclusive Career Services.
                                  </li>{" "}
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    Based on the profile fitment evaluated
                                    during the admission process, users can
                                    qualify for Assured Referrals
                                  </li>{" "}
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    Under Terv’s Assured Referrals, 2 levels of
                                    promises can be offered based on
                                    eligibility:{" "}
                                    <ul
                                      className="my-0"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        listStyle: "none",
                                        padding: "0px",
                                        listStyleType: "disc",
                                        margin: "1em 0px",
                                        display: "block",
                                        paddingLeft: "40px",
                                        marginBottom: "0px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      {" "}
                                      <li
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        Assured Referrals only
                                      </li>{" "}
                                      <li
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        Assured Referrals with minimum expected
                                        CTC
                                      </li>{" "}
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-full max-w-[800px]"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
                maxWidth: "800px",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[10px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  borderRadius: "10px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  backgroundColor: "#242145",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "#242145",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                >
                  <div
                    className="MuiAccordionSummary-content jss14 Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-center gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "center",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "300px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src={question}
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="text-base transition-all sm:w-full"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              fontSize: "1rem",
                              lineHeight: "1.5rem",
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              How does it work?
                            </div>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: "rotate(180deg)",
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "291ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root rounded-b-[30px] bg-v5-green-700 pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="md:pl-[20px]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "20px",
                            }}
                          >
                            <p
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                overflowWrap: "break-word",
                                margin: "0px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                <ul
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    listStyle: "none",
                                    padding: "0px",
                                    listStyleType: "disc",
                                    margin: "1em 0px",
                                    display: "block",
                                    paddingLeft: "40px",
                                  }}
                                >
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    Profile fitment for admissions to Terv
                                    programs is evaluated during the admissions
                                    stage.
                                  </li>{" "}
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    Post successful completions of mandatory
                                    sprints for placement eligibility, users are
                                    referred to partner companies.
                                  </li>{" "}
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    For learners who are eligible for Assured
                                    Referrals, Success Fee is levied post
                                    successful outcome for the learner.
                                  </li>{" "}
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    In the event of not getting placed in 6
                                    months of placement eligibility day, the
                                    success fee is waived off
                                  </li>
                                </ul>
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}
