import { useState } from "react";
const FaqComponent = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '10px 20px',
        width: '80%',
        backgroundColor: '#f0f4f8',
        borderRadius: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: 'black',
        transition: 'all 1s ease',
        alignSelf: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // Vertically centers the content
        }}
      >
        <h4 style={{ 
            margin: '0', 
            flexGrow: 1, 
             }}>
          {question}
        </h4>
        <button
          style={{
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            color: '#333',
            width: '30px', // Static width
            height: '30px', // Static height
            lineHeight: '30px', // Centers the '+' and 'x' vertically
            textAlign: 'center', // Centers the '+' and 'x' horizontally
          }}
          onClick={toggleExpand}
        >
          {isExpanded ? 'x' : '+'}
        </button>
      </div>
      {isExpanded && (
        <div
          style={{
            marginTop: '10px',
            fontSize: '14px',
            lineHeight: '1.5',
            width : "95%",
          }}
        >
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

export default FaqComponent;
