import React from "react";

export default function RecruitersComponent() {
  return (
    <>
      <p
        className="text-v5-neutral-300 text-center leading-loose sm:px-20"
        style={{
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          overflowWrap: "break-word",
          margin: "0px",
          textAlign: "center",
          lineHeight: 2,
          color: "rgb(185 207 202/1)",
          paddingLeft: "5rem",
          paddingRight: "5rem",
        }}
      >
        {" "}
        Recruiters too, look for real project experience when hiring tech
        professionals and here at Terv, we strive to provide just that. We
        empower learners with high-quality applied learning opportunities and
        build skills that translate into career growth and success.
      </p>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}
