import React from "react";
import one from "../assets/Piyush.png";
import oneCmpy from "../assets/Capillary.png";
import two from "../assets/Ramesh.png";
import twoCmpy from "../assets/Jumbotail.png";

export default function FeedbackComponent() {
  return (
    <>
      <div
        className="container mt-16"
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          width: "100%",
          maxWidth: "960px",
          marginTop: "4rem",
          background : "#242145",
        }}
      >
        <div
          className="flex flex-col gap-x-10 md:flex-row"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            display: "flex",
            columnGap: "2.5rem",
            flexDirection: "row",
          }}
        >
          <div
            className="sm:w-full"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            <div
              className="flex items-center justify-center rounded-[30px] bg-v5-green-100 font-rubik sm:h-[250px] md:rounded-[50px]"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#5E35B1",
                fontFamily: "Rubik, sans-serif",
                height: "250px",
                borderRadius: "50px",
              }}
            >
              <p
                className="mb-5 px-8 py-8 text-center leading-relaxed sm:mb-0 sm:py-2 md:px-6"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflowWrap: "break-word",
                  margin: "0px",
                  textAlign: "center",
                  lineHeight: 1.625,
                  marginBottom: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "0.5rem",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  color : "white"
                }}
              >
                "Their cutting edge platform and pedagogical methods are a class
                apart and help groom engineers not only on the technical skills
                but also the tools necessary for today's software development
                industry"
              </p>
            </div>
            <div
              className="flex -translate-y-[36px] flex-col items-center justify-center"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                transform:
                  "translate(0,-36px) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="h-[50px] w-[50px] rotate-45 rounded-[10px] bg-v5-green-100"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  height: "50px",
                  width: "50px",
                  transform:
                    "translate(0,0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                  borderRadius: "10px",
                  backgroundColor: "#5E35B1",
                }}
              />
              <div
                className="mt-2 h-[80px] w-[80px]"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  marginTop: "0.5rem",
                  height: "80px",
                  width: "80px",
                }}
              >
                <div
                  className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-block",
                    verticalAlign: "top",
                  }}
                >
                  <div
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      maxWidth: "200px",
                      display: "block",
                    }}
                  >
                    <img
                      aria-hidden="true"
                      role="presentation"
                      src={one}
                      style={{
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        pointerEvents: "none",
                        verticalAlign: "middle",
                        inset: "0px",
                        margin: "0px",
                        padding: "0px",
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        maxWidth: "100%",
                        display: "block",
                        position: "static",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="mt-2 flex flex-col items-center justify-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  marginTop: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="flex items-center justify-center gap-2"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                  }}
                >
                  <h3
                    className="font-manrope text-lg text-v5-neutral-200"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      fontWeight: 700,
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "1.125rem",
                      lineHeight: "1.75rem",
                      color: "rgb(242 251 247/1)",
                    }}
                  >
                    Somesh Reddy
                  </h3>
                </div>
                <h4
                  className="text-base text-v5-neutral-300 font-manrope"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    fontWeight: 700,
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    color: "rgb(185 207 202/1)",
                  }}
                >
                  Cheif Technical Officer
                </h4>
                <div
                  className="h-[60px] w-[100px]"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    height: "60px",
                    width: "100px",
                  }}
                >
                  <div
                    className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflow: "hidden",
                      position: "relative",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        maxWidth: "200px",
                        display: "block",
                      }}
                    >
                      <img
                        aria-hidden="true"
                        role="presentation"
                        src={oneCmpy}
                        style={{
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          pointerEvents: "none",
                          verticalAlign: "middle",
                          inset: "0px",
                          margin: "0px",
                          padding: "0px",
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          maxWidth: "100%",
                          display: "block",
                          position: "static",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="sm:w-full"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            <div
              className="flex items-center justify-center rounded-[30px] bg-v5-green-100 font-rubik sm:h-[250px] md:rounded-[50px]"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#5E35B1",
                fontFamily: "Rubik, sans-serif",
                height: "250px",
                borderRadius: "50px",
              }}
            >
              <p
                className="mb-5 px-8 py-8 text-center leading-relaxed sm:mb-0 sm:py-2 md:px-6"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflowWrap: "break-word",
                  margin: "0px",
                  textAlign: "center",
                  lineHeight: 1.625,
                  marginBottom: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "0.5rem",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  color : "white"
                }}
              >
                "Terv trains developers to have a hunger for self-learning along
                with good knowledge of tools and methodologies used in
                day-to-day development, which are THE MOST important qualities
                for fast-paced and value-driven companies like Jumbotail.
                Looking forward to hiring more engineers from Terv."
              </p>
            </div>
            <div
              className="flex -translate-y-[36px] flex-col items-center justify-center"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                transform:
                  "translate(0,-36px) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="h-[50px] w-[50px] rotate-45 rounded-[10px] bg-v5-green-100"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  height: "50px",
                  width: "50px",
                  transform:
                    "translate(0,0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                  borderRadius: "10px",
                  backgroundColor: "#5E35B1",
                }}
              />
              <div
                className="mt-2 h-[80px] w-[80px]"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  marginTop: "0.5rem",
                  height: "80px",
                  width: "80px",
                }}
              >
                <div
                  className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-block",
                    verticalAlign: "top",
                  }}
                >
                  <div
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      maxWidth: "200px",
                      display: "block",
                    }}
                  >
                    <img
                      aria-hidden="true"
                      role="presentation"
                      src={two}
                      style={{
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        pointerEvents: "none",
                        verticalAlign: "middle",
                        inset: "0px",
                        margin: "0px",
                        padding: "0px",
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        maxWidth: "100%",
                        display: "block",
                        position: "static",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="mt-2 flex flex-col items-center justify-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  marginTop: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="flex items-center justify-center gap-2"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                  }}
                >
                  <h3
                    className="font-manrope text-lg text-v5-neutral-200"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      fontWeight: 700,
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "1.125rem",
                      lineHeight: "1.75rem",
                      color: "rgb(242 251 247/1)",
                    }}
                  >
                    Jayakumar S
                  </h3>
                </div>
                <h4
                  className="text-base text-v5-neutral-300 font-manrope"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    fontWeight: 700,
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    color: "rgb(185 207 202/1)",
                  }}
                >
                  Cheif Technical Officer
                </h4>
                <div
                  className="h-[60px] w-[100px]"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    height: "60px",
                    width: "100px",
                  }}
                >
                  <div
                    className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflow: "hidden",
                      position: "relative",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        maxWidth: "200px",
                        display: "block",
                      }}
                    >
                      <img
                        aria-hidden="true"
                        role="presentation"
                        src={twoCmpy}
                        style={{
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          pointerEvents: "none",
                          verticalAlign: "middle",
                          inset: "0px",
                          margin: "0px",
                          padding: "0px",
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          maxWidth: "100%",
                          display: "block",
                          position: "static",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}
