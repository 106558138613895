import { useState } from "react";

export default function RoadMap_Sub(){    
    const [ lld , setLld] = useState(false);
    const [lld1 , setLld1] = useState(false);
    const [lld2 , setLld2] = useState(false);

    const [ hld , setHld] = useState(false);

    const [ advanceInterview , setAdvanceInterview] = useState(false);
    return(
        <>
        <div
        className="parent-div mb-20 rounded-[50px] bg-v5-green-500 p-4 text-white sm:p-6 "
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          marginBottom: "5rem",
          borderRadius: "50px",
          backgroundColor: "#5E35B1",
          color: "white !important",
          textColor: "white",
          padding: "1.5rem",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
    <h1
          id="#advanced"
          className="mt-10 mb-12 text-center font-manrope text-2xl font-extrabold capitalize leading-tight sm:text-4xl"
          style={{
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            overflowWrap: "break-word",
            margin: "0px",
            marginTop: "2.5rem",
            marginBottom: "3rem",
            textAlign: "center",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 800,
            textTransform: "capitalize",
            fontSize: "2.25rem",
            lineHeight: "2.5rem",
          }}
        >
          Additional{" "}
          <span
            className="text-v5-green-100"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              color: "rgb(45 248 197/1)",
            }}
          >
            advanced curriculum
          </span>{" "}
          to crack{" "}
          <br
            className="hidden sm:block"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "block",
            }}
          />
          super-dream jobs (15 To 40 LPA){" "}
          <br
            className="hidden sm:block"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "block",
            }}
          />
          at Unicorns and tech giants
        </h1>
        <div
          className="my-10 rounded-[20px] bg-v5-green-500 text-white"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            marginBottom: "2.5rem",
            marginTop: "2.5rem",
            borderRadius: "20px",
            backgroundColor: "#5E35B1",
            color: "white",
          }}
        >
          <div
            className="flex w-full flex-wrap items-stretch gap-y-4 gap-x-4 pt-4 text-v5-neutral-200 sm:px-6"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              alignItems: "stretch",
              columnGap: "1rem",
              rowGap: "1rem",
              paddingTop: "1rem",
              color: "rgb(242 251 247/1)",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
            }}
          >
            <div
              className="w-full"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  backgroundColor: "#242145",
                  borderRadius: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  onClick={()=>{setLld(!lld)}}
                >
                  <div
                    className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-start gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "flex-start",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "100px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src="data:image/svg+xml;charset=utf-8,<svg height='102' width='100' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                            <img
                              aria-hidden="true"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADmklEQVQ4y22UX2gcVRTGv5ndLNG2UBDBB1+lqWL6JlTEh2gUIQ3VkjQ2bWOaZCPZbJJutrkzs7tJIykpJpvszuxuEyLYohbsk+BDLQiKIG36UBV8qcU/WEEU2qRRLGm295M7s7szSfNwOOcO5/zOuWfOuYAtgLzYCduYgi2+gWNchyOWYRvLSmsBCX6Hrc7GdTfGFuPIGztcFmxjN2zjBmyDcAy62rWFp/OmL7bwxPUL2u75Buyx3YAtltxgR9yHbZThCE9so6w5orxzIVnetThafmpxpKwXzTIcowxbeLpmi/uVJBdUhauVLBKOkEqHCoKYTbPl826u/NvIldV9HL/dyvBsPzEfrFDFGBK2kJVv9+DD3A+uDhfGiJkMo192kHyGA3+1Eb8JhtNt1N4fJQqmf11HbIqFZwgf6hgMKeBshl1fdHL474PETylGbg7zscRBYia5BRiAKe0ffKCbIG+wfuEU66b7qMcOMRxrY6ing8iN+VeuAQM39CEBWPUv55VMEie6idQAsTRe7d2j0Ko8OgJVWxDZFNuvdLHv4mtEbxsxHSdKlpdoa8wmoBPIWDCplUwim2H8q3Y+3NhLsoFzn73A0LEW6jPDxELa6+N2VW6tMJQdISaHGb3SxvLGc3z437NcXt3Pl+/28fHzxxhqb6E28S61s3FiPrldhT5Ms8dYnzpOpGI8/12TOzJXV/bzyV8GiT9SxLUhak2vUBs6zsjpHtZNRomi5bdI6craePSsIMZ6ifwEn1hK8OvbL3Hfn33E7xbrbp1kxHqT4UQnsZQhziQJMeADq5wqWS8YfPqDOPdk3mLDhzEil+auxVHWf58gfhx2YXWxw0RR7Xeab1w8whOXDhDnLH8qVFG6mvQ5iw0fD8q1f57n3bVGdt5qpzbdT+RMomRQy41Qn4wxVBTEXIbvXH6bfLCXR+8dIT4ZoH42IVE0vTnU1d5m0/LFT6NcY6NsWuklfhWMWIep1dphuuOiub4Wp35oZfOdLuLOaepn2qUW7yYWU9L11RyxgXlL7rkwyOZvOyQux7njUhcj/YcqoxFouCOo5QTxkSCujlIvHSWaX5d4b0iiZCrgOty30A0y1zF1UupGlNqpqMTEIFEIjFStWm8t9WxSYqiHmFTDbq5X/K6pOXy11lTVh3MpiYWUtxGbhjbwmCpbJVPXLFky4NekgOqBOADHuOmW7GWS277MzqZ1k5UVfADH+BmOaFWs/wGxUzQdB7R9BAAAAABJRU5ErkJggg=="
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                pointerEvents: "none",
                                display: "block",
                                verticalAlign: "middle",
                                margin: "0px",
                                padding: "0px",
                                height: "100%",
                                maxWidth: "none",
                                width: "100%",
                                transition: "opacity 500ms linear",
                                inset: "0px",
                                opacity: 0,
                                backgroundColor: "transparent",
                                position: "absolute",
                                objectFit: "cover",
                              }}
                            />
                            <picture
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "block",
                                maxWidth: "100%",
                              }}
                            >
                              <source
                                type="image/webp"
                                sizes="(min-width: 50px) 50px, 100vw"
                                srcSet="https://www.crio.do/static/833343cb0e49ed2288bb3c9cb08088dc/d5ef2/Eight.webp 50w"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                              <img
                                placeholder="blurred"
                                height={41}
                                width={40}
                                alt="Curriculum"
                                sizes="(min-width: 50px) 50px, 100vw"
                                src="https://www.crio.do/static/833343cb0e49ed2288bb3c9cb08088dc/14f19/Eight.png"
                                srcSet="/static/833343cb0e49ed2288bb3c9cb08088dc/14f19/Eight.png 50w"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  display: "block",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  maxWidth: "none",
                                  position: "absolute",
                                  width: "100%",
                                  transition: "opacity 0.25s linear",
                                  transform: "translateZ(0px)",
                                  willChange: "opacity",
                                  objectFit: "contain",
                                  opacity: 1,
                                }}
                              />
                            </picture>
                            <noscript
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              {
                                '<picture><source type="image/webp" srcSet="/static/833343cb0e49ed2288bb3c9cb08088dc/d5ef2/Eight.webp 50w" sizes="(min-width: 50px) 50px, 100vw"/><img placeholder="blurred" width="100" height="102" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 50px) 50px, 100vw" decoding="async" loading="lazy" src="/static/833343cb0e49ed2288bb3c9cb08088dc/14f19/Eight.png" srcSet="/static/833343cb0e49ed2288bb3c9cb08088dc/14f19/Eight.png 50w" alt="Curriculum"/></picture>'
                              }
                            </noscript>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="transition-all sm:w-full md:text-[18px]"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              Low Level Design
                            </div>
                          </h6>
                          <p
                            className="text-sm text-v5-neutral-300"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              color: "rgb(185 207 202/1)",
                            }}
                          >
                            11 weeks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: `${lld ? 'rotate(180deg)' :'rotate(0deg)'}`,
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {lld &&
                    <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "293ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root bg-v5-green-700 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="md:pl-[54px]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                            }}
                          >
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                <strong
                                  className="text-v5-green-100"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    fontWeight: "bolder",
                                    color: "rgb(45 248 197/1)",
                                  }}
                                >
                                  Low Level Design
                                </strong>{" "}
                                and{" "}
                                <strong
                                  className="text-v5-green-100"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    fontWeight: "bolder",
                                    color: "rgb(45 248 197/1)",
                                  }}
                                >
                                  Object Modeling
                                </strong>{" "}
                                is a key skill for any Developer while creating
                                applications. Learn and apply these design
                                concepts and templates to multiple design
                                problems to gain that edge.
                              </div>
                            </div>
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="md:pl-[54px] md:pt-1"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                              paddingTop: "0.25rem",
                            }}
                          >
                            <h6
                              className="font-manrope text-white"
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                overflowWrap: "break-word",
                                margin: "0px",
                                fontWeight: 700,
                                fontSize: "1rem",
                                fontFamily: "Manrope, sans-serif",
                                color: "white",
                              }}
                            />
                            <div
                              className="mt-10 flex flex-wrap items-stretch gap-5 md:my-4"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                                gap: "1.25rem",
                                marginBottom: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              <div
                                className="mt-[-10px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                      onClick={() => setLld1(!lld1)}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                LLD Intermediate
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${lld1 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {lld1 &&
                                    <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "404ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  Master the art of designing
                                                  and developing industry-grade
                                                  applications using
                                                  object-oriented design
                                                  principles & design patterns.
                                                  Get ready to crack LLD &
                                                  machine coding rounds with a
                                                  structured approach that’s
                                                  easy to follow.
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="md:pt-1"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                paddingTop: "0.25rem",
                                              }}
                                            >
                                              <h6
                                                className="font-manrope text-white"
                                                style={{
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  overflowWrap: "break-word",
                                                  margin: "0px",
                                                  fontWeight: 700,
                                                  fontSize: "1rem",
                                                  fontFamily:
                                                    "Manrope, sans-serif",
                                                  color: "white",
                                                }}
                                              />
                                              <div
                                                className="mb-4 mt-1 grid justify-items-center gap-5 md:grid-cols-2"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.25rem",
                                                  display: "grid",
                                                  justifyItems: "center",
                                                  gap: "1.25rem",
                                                  gridTemplateColumns:
                                                    "repeat(2, minmax(0px, 1fr))",
                                                }}
                                              >
                                                <div
                                                  className="col-span-full w-full"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    gridColumn: "1 / -1",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    className="h-full w-full rounded-[20px] bg-v5-green-700 text-v5-neutral-300 p-4"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      height: "100%",
                                                      width: "100%",
                                                      borderRadius: "20px",
                                                      backgroundColor:
                                                        "#242145",
                                                      padding: "1rem",
                                                      color:
                                                        "rgb(185 207 202/1)",
                                                    }}
                                                  >
                                                    <h6
                                                      className="mb-2 text-white"
                                                      style={{
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        overflowWrap:
                                                          "break-word",
                                                        margin: "0px",
                                                        fontWeight: 700,
                                                        fontSize: "1rem",
                                                        marginBottom: "0.5rem",
                                                        color:
                                                          "white",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Applied OOPs Foundations
                                                      </div>
                                                    </h6>
                                                    <div
                                                      className="md:text-md text-sm"
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1.25rem",
                                                      }}
                                                    >
                                                      <div
                                                        className="mb-2 flex items-start gap-x-2"
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                          marginBottom:
                                                            "0.5rem",
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                          columnGap: "0.5rem",
                                                        }}
                                                      >
                                                        <div
                                                          className="leading-relaxed"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            lineHeight: 1.625,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                            }}
                                                          >
                                                            <ul
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                listStyle:
                                                                  "none",
                                                                padding: "0px",
                                                                listStyleType:
                                                                  "disc",
                                                                margin:
                                                                  "1em 0px",
                                                                display:
                                                                  "block",
                                                                paddingLeft:
                                                                  "20px",
                                                              }}
                                                            >
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Apply OOPs
                                                                concepts in
                                                                practical
                                                                scenarios
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Practice
                                                                creating
                                                                relationships
                                                                (IS-A, HAS-A,
                                                                USES-A) and
                                                                cardinality for
                                                                different use
                                                                cases
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Understand the
                                                                importance of
                                                                OOPs concepts
                                                                through a series
                                                                of Design
                                                                Critique
                                                                sessions
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        <div
                                                          className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            marginBottom:
                                                              "1rem",
                                                            marginTop:
                                                              "0.75rem",
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            columnGap:
                                                              "0.75rem",
                                                            rowGap: "0.75rem",
                                                          }}
                                                        >
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Encapsulation
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Abstraction
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Inheritance
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Polymorphism
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Relationships
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Cardinality
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="col-span-full w-full"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    gridColumn: "1 / -1",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    className="h-full w-full rounded-[20px] bg-v5-green-700 text-v5-neutral-300 p-4"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      height: "100%",
                                                      width: "100%",
                                                      borderRadius: "20px",
                                                      backgroundColor:
                                                        "#242145",
                                                      padding: "1rem",
                                                      color:
                                                        "rgb(185 207 202/1)",
                                                    }}
                                                  >
                                                    <h6
                                                      className="mb-2 text-white"
                                                      style={{
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        overflowWrap:
                                                          "break-word",
                                                        margin: "0px",
                                                        fontWeight: 700,
                                                        fontSize: "1rem",
                                                        marginBottom: "0.5rem",
                                                        color:
                                                          "white",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Designing clean and
                                                        reusable industry grade
                                                        applications
                                                      </div>
                                                    </h6>
                                                    <div
                                                      className="md:text-md text-sm"
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1.25rem",
                                                      }}
                                                    >
                                                      <div
                                                        className="mb-2 flex items-start gap-x-2"
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                          marginBottom:
                                                            "0.5rem",
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                          columnGap: "0.5rem",
                                                        }}
                                                      >
                                                        <div
                                                          className="leading-relaxed"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            lineHeight: 1.625,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                            }}
                                                          >
                                                            <ul
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                listStyle:
                                                                  "none",
                                                                padding: "0px",
                                                                listStyleType:
                                                                  "disc",
                                                                margin:
                                                                  "1em 0px",
                                                                display:
                                                                  "block",
                                                                paddingLeft:
                                                                  "20px",
                                                              }}
                                                            >
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Learn tips &
                                                                tricks to build
                                                                reusable,
                                                                modular and
                                                                extensible
                                                                software
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Learn to write
                                                                bug-free code by
                                                                designing
                                                                testable
                                                                components
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Apply SOLID
                                                                design
                                                                principles when
                                                                designing your
                                                                solution
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Understand the
                                                                importance of
                                                                Design patterns
                                                                and how they can
                                                                simplify problem
                                                                solving
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Learn important
                                                                design patterns
                                                                like Singleton,
                                                                Factory,
                                                                Builder,
                                                                Strategy
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Practice
                                                                converting
                                                                Product
                                                                Requirement Docs
                                                                (PRD) into class
                                                                diagrams and
                                                                sequence
                                                                diagrams by
                                                                applying design
                                                                principles and
                                                                patterns
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Go deeper into
                                                                the world of
                                                                schema design
                                                                and understand
                                                                how it can
                                                                impact
                                                                extensibility,
                                                                speed & querying
                                                                capabilities of
                                                                your software
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        <div
                                                          className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            marginBottom:
                                                              "1rem",
                                                            marginTop:
                                                              "0.75rem",
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            columnGap:
                                                              "0.75rem",
                                                            rowGap: "0.75rem",
                                                          }}
                                                        >
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Testable Code
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              SOLID Principles
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Object Oriented
                                                              Design
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Design Patterns
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Singleton
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Factory
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Builder
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Strategy
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Class diagram
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Sequence diagram
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Machine Coding
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Schema design
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Normalization
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                  }
                                </div>
                              </div>
                              <div
                                className="mt-[-10px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                      onClick={() => setLld2(!lld2)}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                LLD Advanced
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${lld2 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {lld2 &&
                                    <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "333ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                    }}
                                                  >
                                                    Learn the art of designing
                                                    robust individual components
                                                    of a large system, starting
                                                    with Requirements. Evaluate
                                                    the key tradeoffs and apply
                                                    structured design templates
                                                    to problem statements across
                                                    multiple domains.
                                                  </div>
                                                  <ul
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      listStyle: "none",
                                                      padding: "0px",
                                                      listStyleType: "disc",
                                                      margin: "1em 0px",
                                                      display: "block",
                                                      paddingLeft: "20px",
                                                    }}
                                                  >
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Learn best practices for
                                                      designing APIs - using the
                                                      right HTTP status,
                                                      versioning, naming
                                                      conventions, query vs path
                                                      parameters and more
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Learn how to add
                                                      concurrency to your
                                                      application, take care of
                                                      communication between
                                                      different threads and
                                                      ensure data consistency
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Understand how
                                                      latency/throughput impacts
                                                      software design and the
                                                      difference between I/O and
                                                      CPU intensive applications
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Learn the secret behind
                                                      building large sub-systems
                                                      incrementally
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.75rem",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  columnGap: "0.75rem",
                                                  rowGap: "0.75rem",
                                                }}
                                              >
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    API Design
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    API Versioning
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Query param
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Path param
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Threads
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Data consistency
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    I/O vs CPU intensive
                                                    application
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Latency
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                }
              </div>
            </div>
            <div
              className="w-full"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  backgroundColor: "#242145",
                  borderRadius: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  onClick={()=>{setHld(!hld)}}
                >
                  <div
                    className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-start gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "flex-start",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "100px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src="data:image/svg+xml;charset=utf-8,<svg height='102' width='100' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                            <img
                              aria-hidden="true"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD60lEQVQ4y31Uf0xbVRQ+r7hlBqYkC/8YTRQ6QMaPNiSCMYozVZyDjg1kbE4UcAMb0RBK73u0TAkzM8sQ+l5d2cimS0y2hZnoHCYmmixbTIA54oyDMWkWIGZjkwpD5Ie1n3n3vrYwiX+c3PvuO+e73/nOPYdIZURelkCqfIBUdpE0eYBU1k8a6ydV5qtk2PIz4cN9L5LK9pNXjudYpMqJpMqDpMogTTcGsWcx88ogr7LyPLqXxV5lg6S6EolU1m04zZPGQqTKIUljKyzB7wyt72oMmXzim9TIKod4DI/loCd1htPGLWFuGgtTpyIYtXuQ4Hfi58k83AzmIqm7AdTeAkn372wG9+VxLGwAzlAUTKSrOyD+aBNOD9vw3oVX8dzZt3BvLhPhxQwUffUmynrLcWooH0nH3uWXSnoMl8AAFRuecjjOx0DtblR9WQpgI+bmMxH8MwdAJhZCmzA0mY7hyY34/d5jUPq2gTo8MGkuQUQwDFOMrhyWVP1nC6hiD85fsiKMLEzPZaOnPw/d31tw7U4ahm+bMRB8CsVXFNAhD0w+FpHLANREdSWdZYeCvJ46OM/Y8ON1C2dW+1kByPYyyGLDroP5GA2aceWOBdoNO6yf10TSjlU8AviAzwU6tB8nhwoBpGDpn2yM383BQ+/YYWp4A5KjEutKtqAvkI7rd1Px1/wj8F8tAB1uQZzP9V9ArkWHB3u+2IHvfrJiYsqKmdkMZJ2oAnW0gbwHYOmuxMitFPRPZKDnciaqzm8DdXpWZ0gqA/ndoMZKkH0rzvyQh9mlNPSOWlH69U5sP1eBbwK5mJhJwYlLVlBZERI/rIHkbwZ5DbD7AaUuN6j+dbymPYuFUBaGb5kxFkzGePAJjE0lY3w6Gb/+kYrRKTN2aflY31qNuK5mQWY1htKRZpCyF0/LNlwbexK9twvQeG4zBidSMTKVhtpPn0HbaSsC89lwXCjHmtZamPz/A6hTNx1zg8p2Y5PjJbQEXcg4WIrLN834bSEdae4iUO6LcA/txY5fPKDDTHRNtLejD9t4Q7oWR92g3eXYULEVbbMumN8vQZb8PAp9hXi8qRiUuxn1V+tReUMWgD7ObNnDjmxU0T6SXwEpDjxaV4J9ARfiW6tBO4vwoGM7NnxUA7KXoG7gbeR8q/c6W7X1/uY3CMoC2Kdg7ccNWOPXW9GJddWv4OF9W7D2kyYuS9JxpxhpQrvIcNDXReKzUGiwuCJ9Y85JXhdMrXUwfVAL6mwSOnnlZf3L/ReNuD4d0HbfQI30ZaxyevWPuGPFiw5WJsadSFk/e0HXULdi0uSRZTeFDQcY2sYsehYtxBKpcoA0Ztex/gUiQVeCe+RO+gAAAABJRU5ErkJggg=="
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                pointerEvents: "none",
                                display: "block",
                                verticalAlign: "middle",
                                margin: "0px",
                                padding: "0px",
                                height: "100%",
                                maxWidth: "none",
                                width: "100%",
                                transition: "opacity 500ms linear",
                                inset: "0px",
                                opacity: 0,
                                backgroundColor: "transparent",
                                position: "absolute",
                                objectFit: "cover",
                              }}
                            />
                            <picture
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "block",
                                maxWidth: "100%",
                              }}
                            >
                              <source
                                type="image/webp"
                                sizes="(min-width: 50px) 50px, 100vw"
                                srcSet="https://www.crio.do/static/59cd12800710874e86b5c9cc051e7b35/d5ef2/Nine.webp 50w"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                              <img
                                placeholder="blurred"
                                height={41}
                                width={40}
                                alt="Curriculum"
                                sizes="(min-width: 50px) 50px, 100vw"
                                src="https://www.crio.do/static/59cd12800710874e86b5c9cc051e7b35/14f19/Nine.png"
                                srcSet="/static/59cd12800710874e86b5c9cc051e7b35/14f19/Nine.png 50w"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  display: "block",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  maxWidth: "none",
                                  position: "absolute",
                                  width: "100%",
                                  transition: "opacity 0.25s linear",
                                  transform: "translateZ(0px)",
                                  willChange: "opacity",
                                  objectFit: "contain",
                                  opacity: 1,
                                }}
                              />
                            </picture>
                            <noscript
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              {
                                '<picture><source type="image/webp" srcSet="/static/59cd12800710874e86b5c9cc051e7b35/d5ef2/Nine.webp 50w" sizes="(min-width: 50px) 50px, 100vw"/><img placeholder="blurred" width="100" height="102" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 50px) 50px, 100vw" decoding="async" loading="lazy" src="/static/59cd12800710874e86b5c9cc051e7b35/14f19/Nine.png" srcSet="/static/59cd12800710874e86b5c9cc051e7b35/14f19/Nine.png 50w" alt="Curriculum"/></picture>'
                              }
                            </noscript>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="transition-all sm:w-full md:text-[18px]"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              High Level Design
                            </div>
                          </h6>
                          <p
                            className="text-sm text-v5-neutral-300"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              color: "rgb(185 207 202/1)",
                            }}
                          >
                            11 weeks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: `${hld ? 'rotate(180deg)' :'rotate(0deg)'}`,
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {hld &&
                    <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "319ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root bg-v5-green-700 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="md:pl-[54px]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                            }}
                          >
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                Experience the life of a Senior Software
                                developer at a top product company. Work on a{" "}
                                <strong
                                  className="text-v5-green-100"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    fontWeight: "bolder",
                                    color: "rgb(45 248 197/1)",
                                  }}
                                >
                                  LIVE production system
                                </strong>{" "}
                                deployed in{" "}
                                <strong
                                  className="text-v5-green-100"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    fontWeight: "bolder",
                                    color: "rgb(45 248 197/1)",
                                  }}
                                >
                                  AWS
                                </strong>{" "}
                                simulating real-world scenarios that you must
                                solve in order to understand distributed system
                                concepts, design patterns, and cloud-computing.
                              </div>
                            </div>
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="md:pl-[54px] md:pt-1"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                              paddingTop: "0.25rem",
                            }}
                          >
                            <h6
                              className="font-manrope text-white"
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                overflowWrap: "break-word",
                                margin: "0px",
                                fontWeight: 700,
                                fontSize: "1rem",
                                fontFamily: "Manrope, sans-serif",
                                color: "white",
                              }}
                            />
                            <div
                              className="mt-10 flex flex-wrap items-stretch gap-5 md:my-4"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                                gap: "1.25rem",
                                marginBottom: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              <div
                                className="mt-[-10px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                Architecture & High Level Design
                                                Choice
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${hld1 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "319ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                    }}
                                                  >
                                                    Understand various design
                                                    choices available across
                                                    compute, data-store, and
                                                    communication required to
                                                    build a large scale
                                                    distributed system like
                                                    Flipkart and learn the art
                                                    of making the right
                                                    trade-offs. You will be
                                                    working hands-on with
                                                    ZCommerce, Terv’s
                                                    distributed microservices
                                                    based scalable application
                                                    on the cloud.
                                                  </div>
                                                  <ul
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      listStyle: "none",
                                                      padding: "0px",
                                                      listStyleType: "disc",
                                                      margin: "1em 0px",
                                                      display: "block",
                                                      paddingLeft: "20px",
                                                    }}
                                                  >
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Understand the ZCommerce
                                                      Microservice architecture
                                                      and contrast it with other
                                                      styles like event-driven,
                                                      big-compute, big-data, and
                                                      more
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Learn why ZCommerce uses
                                                      both EC2 (IaaS) & Elastic
                                                      Beanstalk (PaaS) and what
                                                      other options were
                                                      evaluated
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Classify workloads in
                                                      ZCommerce as read-heavy,
                                                      write-heavy, transactional
                                                      etc to figure out the
                                                      right data store for each
                                                      workload
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Understand the advantages
                                                      of choosing REST API over
                                                      gRPC
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Decrease the response time
                                                      of ZCommerce website using
                                                      CDN
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="mt-[-10px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                Distributed System Concepts
                                                through solving real world
                                                scenarios
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${hld2 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "353ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                    }}
                                                  >
                                                    Solve multiple real world
                                                    scenarios by applying
                                                    various distributed system
                                                    concepts and cloud design
                                                    patterns.
                                                  </div>
                                                  <ul
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      listStyle: "none",
                                                      padding: "0px",
                                                      listStyleType: "disc",
                                                      margin: "1em 0px",
                                                      display: "block",
                                                      paddingLeft: "20px",
                                                    }}
                                                  >
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Get a bird's-eye view of
                                                      ZCommerce Microservices
                                                      and how they communicate
                                                      with each other
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Find bugs in the ordering
                                                      flow by tracing API
                                                      requests across different
                                                      subsystems from the
                                                      browser all the way to the
                                                      database using Browser Dev
                                                      tool and Kibana
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Figure out why a user is
                                                      not able to access
                                                      specific pages by
                                                      understanding
                                                      authorization policies
                                                      enforced by API Gateway
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Understand how to handle
                                                      Clickstream data traffic
                                                      spikes effectively using
                                                      Kafka
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Avoid duplicate orders
                                                      from user scenarios such
                                                      as accidental multiple
                                                      clicks using idempotency
                                                      pattern
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Keep the checkout
                                                      experience lightning fast
                                                      by processing non-critical
                                                      user flows in an
                                                      asynchronous manner
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Handle retries and
                                                      throttling when
                                                      integrating with
                                                      rate-limited external APIs
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Create Lambdas to extend
                                                      order processing
                                                      functionality and trigger
                                                      them through webhooks
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.75rem",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  columnGap: "0.75rem",
                                                  rowGap: "0.75rem",
                                                }}
                                              >
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Code Comprehension
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Swagger
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Distributed Tracing
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Kibana
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Authentication
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Authorization
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    API Gateway
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Message Queues
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Queue-based Load Leveling
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Idempotency pattern
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Pub-sub pattern
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Rate Limiting
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Retry Pattern
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Third party API
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Lambda
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Webhook
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Kafka Partition
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Cloud Design Patterns
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="mt-[-10px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                Building for Scale leveraging
                                                best practices for Cloud
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${hld3 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "358ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                    }}
                                                  >
                                                    After setting up the initial
                                                    system, start solving scale
                                                    and performance challenges
                                                    to ensure best-in-class user
                                                    experience.
                                                  </div>
                                                  <ul
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      listStyle: "none",
                                                      padding: "0px",
                                                      listStyleType: "disc",
                                                      margin: "1em 0px",
                                                      display: "block",
                                                      paddingLeft: "20px",
                                                    }}
                                                  >
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Troubleshoot multiple API
                                                      performance issues by
                                                      analyzing metrics across
                                                      different layers using
                                                      Grafana and MySQL
                                                      Workbench
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Design optimal solutions
                                                      to manage load for
                                                      BigBillionDay type events
                                                      using caching
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Auto-scale notification
                                                      service to send flash sale
                                                      alerts to millions of
                                                      users using horizontal
                                                      scaling and Load Balancer
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Understand how to handle
                                                      Clickstream data traffic
                                                      spikes are handled
                                                      effectively using Kafka
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Understand how large
                                                      companies manage fast &
                                                      concurrent launches
                                                      leveraging CI/CD
                                                      pipelines, different
                                                      environments (dev, stage,
                                                      prod, test) and Dev-Ops
                                                      best practices
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Do a rolling update for
                                                      the order processing
                                                      lambda function you have
                                                      written using a Load
                                                      Balancer
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Understand important
                                                      aspects of a high
                                                      availability system such
                                                      as Replication, Sharding,
                                                      Circuit Breaker
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.75rem",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  columnGap: "0.75rem",
                                                  rowGap: "0.75rem",
                                                }}
                                              >
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    API Performance
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Monitoring
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Metrics
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Grafana
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    MySQL Workbench
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    DB Indexes
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Caching
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Cache Invalidation
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Auto-Scaling
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Horizontal Scaling
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Vertical Scaling
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    DevOps
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    CI/CD Pipeline
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Load Balancer
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Rolling Update
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Replication
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Sharding
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Circuit Breaker
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Resource Pooling
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                }
              </div>
            </div>
            <div
              className="w-full"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  backgroundColor: "#242145",
                  borderRadius: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  onClick={()=>{setAdvanceInterview(!advanceInterview)}}
                >
                  <div
                    className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-start gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "flex-start",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "100px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src="data:image/svg+xml;charset=utf-8,<svg height='102' width='100' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                            <img
                              aria-hidden="true"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADgElEQVQ4y42VXWwUVRTH/zutRYzRN0h84q1C290EY8IGNK0IbflQ8aNqbSFtrLappGl3d+6dbWMMIWKItHRmt2KiMcZEeJCHCoHAg9HESOKDLxpBqPaD4jbE6kq7XaSd+ZuZuzuz2xDCw8k5N3Pvb//n3HPPAqYATFENU56HKaZhylmYYlb5e1lxj5iBKcYx6jEAHJdukIUlCEtS+dXx3UyWeEmYIgtTrwZMedb7YMo8TGErk3bIEnZFSre1lLBhSRveWtiapb7DKu71LF+AXoQHUkAHlnBcH0q5a4P4cIg4liRGBDEi1XokSc3b76lzYAplCphHAJP0YcMGH0rH2HWhhacvN/DSTJTfTm6l9VMzN37eQ4wMURuTxKgPpQ8NYKIAS3LDqV5enXuCXHmc9vImLt+p8YwrG3lrqY67PnmJOJygdiJZqlRB/UVKOhgWxMkEMRnje389Rzu3ifMLEc4vhnlrKcxsLsLllTpenazlw/uaCKOHoY+SLEnZcRWq2xqVxJhO/DpAzMSJ2UHKmy+QTg0X74SZ+TvCqbkI/10KM78SZn3fU0S0kdqRPiJtFJVS1dBN9ZgkxuPEjTjx2wBx9m1iWue2T/dy8koNv/r+SQ59FiVZy6X/6tho1BObt7Oiu404Majq6UL9vnJv8bsC8Jd+4oNWVlzrJ871seXyK8zlajmfDXPhdphz/0T4WMuzRHQ7Q/tfJcYKtfSB3mVI4ps4cT1G/BEn/tS9WlZm4sSUwZczb3BhsY5kDY+e2kJsaaL29DNEd/s9FH6pB8CJGPF7jLgWY+XEADFh8LWbrfz5xmY+2tZI1O8kGnYQhw4GCgttEzyn45K4kCBmYir1jE7M6cpPJFiZSXDrx7uJtj18sL2JoYPt5ekqhW7beFDVPq7SLxLEeC/xdRdxuosVJzv5SGaA6398i1Vv7uGajl2s6n6eGE3Qb5eStnFKnl6Q/tEE0dtBNO4mojuY/mEbX0w1EK/v5drOZmrvdhHp1Y0tHZTBiq/GjdNSFdu9sMP9XDfcw7V6Kx840Myqnn1BzUwRwNxzZcOh/KEHh8YMwhr0FGmHuhl6/51iMzv+gFBnci7wYuEX8t5YKhlhJePJDhXjdNJGylCxVdxTFCXOAJZRDUtmlZq7DM7VA7VUueWnTY+Rkt6AhRdYchymnLq/8V/2NzANS55HSlTDEvgfnO1EW125zvQAAAAASUVORK5CYII="
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                pointerEvents: "none",
                                display: "block",
                                verticalAlign: "middle",
                                margin: "0px",
                                padding: "0px",
                                height: "100%",
                                maxWidth: "none",
                                width: "100%",
                                transition: "opacity 500ms linear",
                                inset: "0px",
                                opacity: 0,
                                backgroundColor: "transparent",
                                position: "absolute",
                                objectFit: "cover",
                              }}
                            />
                            <picture
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "block",
                                maxWidth: "100%",
                              }}
                            >
                              <source
                                type="image/webp"
                                sizes="(min-width: 50px) 50px, 100vw"
                                srcSet="https://www.crio.do/static/eb0d36d5b4d5094eb33fc01d215a1e93/d5ef2/Six.webp 50w"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                              <img
                                placeholder="blurred"
                                height={41}
                                width={40}
                                alt="Curriculum"
                                sizes="(min-width: 50px) 50px, 100vw"
                                src="https://www.crio.do/static/eb0d36d5b4d5094eb33fc01d215a1e93/14f19/Six.png"
                                srcSet="/static/eb0d36d5b4d5094eb33fc01d215a1e93/14f19/Six.png 50w"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  display: "block",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  maxWidth: "none",
                                  position: "absolute",
                                  width: "100%",
                                  transition: "opacity 0.25s linear",
                                  transform: "translateZ(0px)",
                                  willChange: "opacity",
                                  objectFit: "contain",
                                  opacity: 1,
                                }}
                              />
                            </picture>
                            <noscript
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              {
                                '<picture><source type="image/webp" srcSet="/static/eb0d36d5b4d5094eb33fc01d215a1e93/d5ef2/Six.webp 50w" sizes="(min-width: 50px) 50px, 100vw"/><img placeholder="blurred" width="100" height="102" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 50px) 50px, 100vw" decoding="async" loading="lazy" src="/static/eb0d36d5b4d5094eb33fc01d215a1e93/14f19/Six.png" srcSet="/static/eb0d36d5b4d5094eb33fc01d215a1e93/14f19/Six.png 50w" alt="Curriculum"/></picture>'
                              }
                            </noscript>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="transition-all sm:w-full md:text-[18px]"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              Advanced Interview Blitz
                            </div>
                          </h6>
                          <p
                            className="text-sm text-v5-neutral-300"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              color: "rgb(185 207 202/1)",
                            }}
                          >
                            2 weeks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: `${advanceInterview ? 'rotate(180deg)' :'rotate(0deg)'}`,
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {advanceInterview &&
                    <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "268ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root bg-v5-green-700 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="md:pl-[54px]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                            }}
                          >
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="md:pl-[54px] md:pt-1"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                              paddingTop: "0.25rem",
                            }}
                          >
                            <h6
                              className="font-manrope text-white"
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                overflowWrap: "break-word",
                                margin: "0px",
                                fontWeight: 700,
                                fontSize: "1rem",
                                fontFamily: "Manrope, sans-serif",
                                color: "white",
                              }}
                            />
                            <div
                              className="mt-10 flex flex-wrap items-stretch gap-5 md:my-4"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                                gap: "1.25rem",
                                marginBottom: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              <div
                                className="mt-[-30px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-30px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                Low Level Design
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${advanceLld ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "314ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                    }}
                                                  >
                                                    Prepare to take on LLD
                                                    interviews with confidence
                                                  </div>
                                                  <ul
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      listStyle: "none",
                                                      padding: "0px",
                                                      listStyleType: "disc",
                                                      margin: "1em 0px",
                                                      display: "block",
                                                      paddingLeft: "20px",
                                                    }}
                                                  >
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Learn the art of cracking
                                                      machine coding rounds with
                                                      focus on coming up with
                                                      quick designs and
                                                      structured execution
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Optimize code by using
                                                      better data structures
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Practice design questions
                                                      such as BookMyShow,
                                                      Splitwise, and Trello,
                                                      commonly asked in LLD
                                                      rounds conducted by
                                                      leading product companies
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.75rem",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  columnGap: "0.75rem",
                                                  rowGap: "0.75rem",
                                                }}
                                              >
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    API Design
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    API Versioning
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Query param
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Path param
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Threads
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Data consistency
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    I/O vs CPU intensive
                                                    application
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Latency
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="mt-[-10px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                High Level Design Challenges
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${advanceHld ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "295ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                    }}
                                                  >
                                                    Prepare to take on HLD
                                                    interviews with confidence.
                                                  </div>
                                                  <ul
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      listStyle: "none",
                                                      padding: "0px",
                                                      listStyleType: "disc",
                                                      margin: "1em 0px",
                                                      display: "block",
                                                      paddingLeft: "20px",
                                                    }}
                                                  >
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Utilize the practical
                                                      knowledge gained to solve
                                                      real world design problems
                                                      often asked in interviews
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Learn how to effectively
                                                      design systems
                                                      prioritizing right
                                                      use-cases and making the
                                                      appropriate tradeoffs
                                                    </li>
                                                    <li
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                      }}
                                                    >
                                                      Practice commonly asked
                                                      interview questions like
                                                      design Twitter timeline,
                                                      Notification system,
                                                      BookMyShow, Hotstar
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.75rem",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  columnGap: "0.75rem",
                                                  rowGap: "0.75rem",
                                                }}
                                              >
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    HLD
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    System Design
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Interview Preparation
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
    </div>
    </>
    );
}