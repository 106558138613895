import React from "react";

export default function ProgramFee() {
  return (
    <>
      <h2
        className="z-[300] mb-5 font-manrope font-extrabold capitalize leading-snug md:px-32 text-v5-neutral-200"
        style={{
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          overflowWrap: "break-word",
          margin: "0px",
          fontSize: "2rem",
          zIndex: 300,
          marginBottom: "1.25rem",
          fontFamily: "Manrope, sans-serif",
          fontWeight: 800,
          textTransform: "capitalize",
          lineHeight: 1.375,
          color: "rgb(242 251 247/1)",
          paddingLeft: "8rem",
          paddingRight: "8rem",
          textAlign: "center",
        }}
      >
        Program Fee and Scholarships
      </h2>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}
