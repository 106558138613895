import React from "react";

export default function ImmersiveProgram() {
  return (
    <>
      <div
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          color: "rgb(242 251 247/1)",
          fontFamily: "Manrope, sans-serif",
          fontSize: "2rem",
          fontWeight: 800,
          font: "800 32px / 44px Manrope, sans-serif",
          lineHeight: 1.375,
          textAlign: "center",
          textTransform: "capitalize",
          width: "30%",
          marginLeft : "auto",
          marginRight : "auto",
          boxSizing: "border-box",
          display: "block",
        }}
      >
        Get Hired in Top Tech—An Intensive Program for High-Growth Careers!
        {/* <br
          className="sm:block hidden"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            display: "block",
          }}
        />
        Top-Tech jobs at the{" "}
        <br
          className="sm:block hidden"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            display: "block",
          }}
        />
        fastest-growing companies */}
      </div>
      <div
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          color: "rgb(255 255 255/1)",
          fontWeight: 300,
          font: '300 16px / 32px Rubik, -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          lineHeight: 2,
          textAlign: "center",
          marginBottom : "40px",
          marginTop : "20px",
          width : "70%",
          marginLeft : "auto",
          marginRight : "auto",
        }}
      >
        Get Assured Placement in top tech companies by mastering in-demand
        Full-Stack or Backend developer skills through our carefully curated
        hands-on, work-like, project-based fellowship program.
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}



