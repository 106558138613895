import React from "react";

export default function MentorComponent({
    name,
    imageUrl,
    companyName,
    subject,
}) {
  return (
    <>
      <div
        className="people-list-item glider-slide active visible left-2 mb-20"
        style={{
          boxSizing: "inherit",
          placeContent: "center flex-start",
          userSelect: "none",
          minWidth: "15rem",
          display: "flex",
          WebkitBoxPack: "start",
          height: "auto",
          width: "290px",
          cursor: "grab",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="people-card"
          style={{
            boxSizing: "inherit",
            border: "0.1rem solid rgb(214, 222, 229)",
            borderRadius: "0.8rem",
            flex: "1 1 0%",
            transition:
              "box-shadow 0.2s ease-out, -webkit-box-shadow 0.2s ease-out",
            WebkitBoxFlex: "1",
            marginRight: "1rem",
            marginLeft: "1rem",
          }}
        >
            <img
              className="people-card__img"
              src={imageUrl}
              style={{
                borderStyle: "none",
                boxSizing: "inherit",
                maxWidth: "100%",
                objectFit: "cover",
                boxSizing: "inherit",
                display: "flex",
                position: "relative",
                height: "18.4rem",
                backgroundColor: "rgb(173, 173, 173)",
                WebkitBoxPack: "center",
                justifyContent: "center",

              }}
            />
          <div
            className="people-card__detail"
            style={{ boxSizing: "inherit", padding: "0px 2rem" }}
          >
            <div
              className="people-card__name"
              style={{
                boxSizing: "inherit",
                fontSize: "2.4rem",
                fontWeight: 700,
                color: "#5e35b1",
              }}
            >
              {name}
            </div>
            <div
              className="people-card__company"
              style={{
                boxSizing: "inherit",
                display: "flex",
                WebkitBoxAlign: "center",
                alignItems: "center",
              }}
            >
              {"Ex"}
              <span
                className="m-l-5 companies facebook half"
                style={{
                  boxSizing: "inherit",
                  marginLeft: "0.5rem",
                  display: "block",
                }}
              >
                {companyName}
              </span>
            </div>
            <hr
              style={{
                overflow: "visible",
                height: "0px",
                boxSizing: "inherit",
                borderTop: "0.1rem solid rgb(214, 214, 214)",
                transition: "0.2s ease-out",
              }}
            />
            <a
              className="modal-trigger tuition-fee__button gtm-track-element"
              style={{
                backgroundColor: "transparent",
                boxSizing: "inherit",
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
              <div
                className="people-card__more"
                style={{
                  boxSizing: "inherit",
                  transition: "color 0.2s ease-out",
                  fontSize: "1.6rem",
                  fontWeight: 800,
                  color: "rgb(0, 65, 202)",
                  marginBottom: "1rem",
                  textAlign: "center"
                }}
              >
                {subject}
              </div>
            </a>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  line-height: 1.15;
  text-size-adjust: 100%;
  overflow: hidden visible;
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100vh;
}

body {
  margin: 0px;
  box-sizing: inherit;
  color: rgb(91, 111, 129);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  line-height: 1.4;
  font-family: Mulish, sans-serif;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}
`,
        }}
      />
    </>
  );
}
