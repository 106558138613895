import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CircularProgress, Grid } from "@material-ui/core";
import moment from "moment";
import IdleTimer from "react-idle-timer";
import qs from "query-string";
import jwt from "jwt-decode";
import AppRoute from "../routes/route";
import api from "../api/baseConfig";
import ApiHelper from "../api/apiHelper";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getUserStudents,
  getCustomers,
} from "../store/StudentAndCustomer/actions";
import { sessionPrivileges } from "privileges";
import { getUserProfile } from "../store/UserProfile/actions";
import { handleLoggedIn } from "../store/loggedIn/actions";
import { authProtectedRoutes } from "../routes";
import Layout from "Main/Layout";
import { uid } from "react-uid";
import Page404 from "../pages/Utility/Page404";
import Cookies from "universal-cookie";

function AppNew() {
  const cookies = new Cookies();
  const [initialCheckCompleted, setInitialCheckCompletion] = useState(false);
  const [
    partnerUserAuthenticationCompleted,
    setPartnerUserAuthenticationCompletion,
  ] = useState(false);
  const [
    practiceSSOUserAuthenticationCompleted,
    setpracticeSSOUserAuthenticationCompletion,
  ] = useState(false);
  const userPrivileges = sessionPrivileges();

  const idleTimer = useRef(null);

  const currentStore = useSelector((state) => ({
    students: state.StudentAndCustomerReducer.UserStudents,
    customers: state.StudentAndCustomerReducer.Customers,
    userProfile: state.UserProfileReducer.UserData,
  }));

  const loggedInReducer = useSelector((state) => state.LoggedInReducer);
  const headerState = userPrivileges.isLoggedIn;
  const dispatch = useDispatch();

  async function handleOnIdle() {
    await ApiHelper.axiosCallPost(
      `${api.baseURL}terv/saveLoginActivity`,
      "post",
      {
        emailId: userPrivileges.userName,
        sessionId: sessionStorage.getItem("session_id"),
        name: "",
        logoutTime: moment().format("yyyy-MM-DDTHH:mm:ss"),
      }
    )
      .then(() => {
        const url = cookies.get("returnLink", { path: "/" });
        cookies.remove("userToken", { path: "/" });
        cookies.remove("refreshToken", {
          path: "/",
        });
        cookies.remove("userId", { path: "/" });
        localStorage.clear();
        sessionStorage.clear();

        cookies.remove("returnLink", { path: "/" });
        window.location.assign(url ? url : "/login");
      })
      .catch(() => {
        const url = cookies.get("returnLink", { path: "/" });
        cookies.remove("userToken", { path: "/" });
        cookies.remove("refreshToken", {
          path: "/",
        });
        cookies.remove("userId", { path: "/" });
        localStorage.clear();
        sessionStorage.clear();

        cookies.remove("returnLink", { path: "/" });
        window.location.assign(url ? url : "/login");
      });
  }

  useEffect(() => {
    async function getData() {
      const cookies = new Cookies();
      try {
        const assessmentId = qs.parse(window.location.search)?.test;
        const ehkTest = qs.parse(window.location.search)?.ehkTest;

        if (assessmentId) {
          window.location.href = `/take-test?id=${assessmentId}`;
        } else if (ehkTest) {
          localStorage.setItem("ExternalPracticePackageId", ehkTest);
          window.location.href =
            "https://dev-6999491.okta.com/app/dev-6999491_reactsamltest_1/exkytdnavaQOp7vFb5d6/sso/saml";
        } else if (window.location.pathname?.includes("samlservice")) {
          const externalPracticePackageId = qs.parse(
            window.location.search
          )?.ehk;

          const token = qs.parse(window.location.search)?.token;
          const userId = qs.parse(window.location.search)?.userId;
          const userName = `${qs.parse(window.location.search)?.firstName} ${
            qs.parse(window.location.search)?.lastName
          }`;
          const redirectionLocation = qs.parse(window.location.search)?.product;
          if (!externalPracticePackageId || !userId || !userName || !token) {
          }

          // const decodedToken = jwt(token);

          cookies.set("userToken", token, { path: "/" });

          // sessionStorage.setItem(
          //   "privileges",
          //   decodedToken?.authorities?.map((a) => {
          //     return a?.authority;
          //   })
          // );

          if (externalPracticePackageId) {
            localStorage.setItem(
              "ExternalPracticePackageId",
              externalPracticePackageId
            );
          }
          localStorage.setItem("IsPartnerUser", true);
          setPartnerUserAuthenticationCompletion(true);
          if (token && userId) {
            // sessionStorage.setItem("userToken", token);
            sessionStorage.setItem("user_id", userId);
            sessionStorage.setItem("user_name", userName);
            setInitialCheckCompletion(true);
            setPartnerUserAuthenticationCompletion(true);
            if(redirectionLocation == 'course') {
              const decodedId = window.atob(externalPracticePackageId)
              window.location.href = `/course-overview/${decodedId}`;
            } else {
              window.location.href = "/view-practice-questions?externalPack";
            }
          } else {
            window.location.href = "/login";
          }
        } else if (window.location.href?.includes("practiceSSO")) {
          const practicePackageId = qs.parse(window.location.search)?.pid;

          const token = qs.parse(window.location.search)?.token;
          const userId = qs.parse(window.location.search)?.userId;
          const userName = `${qs.parse(window.location.search)?.firstName} ${
            qs.parse(window.location.search)?.lastName
          }`;

          if (!practicePackageId || !userId || !userName || !token) {
            window.location.href = "/login";
          }

          cookies.set("userToken", token, { path: "/" });
          // const decodedToken = jwt(token);
          // sessionStorage.setItem("privileges", decodedToken.authorities);
          // sessionStorage.setItem(
          //   "privileges",
          //   decodedToken?.authorities?.map((a) => {
          //     return a?.authority;
          //   })
          // );
          localStorage.setItem("IsPracticeSSO", true);
          setpracticeSSOUserAuthenticationCompletion(true);

          if (token && userId) {
            sessionStorage.setItem("userToken", token);
            sessionStorage.setItem("user_id", userId);
            sessionStorage.setItem("user_name", userName);
            sessionStorage.setItem("labId", practicePackageId);
            setInitialCheckCompletion(true);
            setpracticeSSOUserAuthenticationCompletion(true);
            window.location.href = "/view-practice-questions";
          } else {
            window.location.href = "/login";
          }
        } else {
          setInitialCheckCompletion(true);
          if (userPrivileges && userPrivileges.userName) {
            if (!currentStore.userProfile) {
              dispatch(getUserProfile(userPrivileges.userName));
            }

            if (
              !currentStore.students &&
              userPrivileges &&
              userPrivileges.isAdmin
            ) {
              dispatch(getUserStudents(userPrivileges.userName));
            }
          }
          if (
            userPrivileges &&
            userPrivileges.isSuperAdmin &&
            !currentStore.customers
          ) {
            dispatch(getCustomers());
          }

          const isPublicAccessRoute =
            window.location.href?.includes("login") ||
            window.location.href?.includes("/termsAndConditions") ||
            window.location.href?.includes("/privacy") ||
            window.location.href?.includes("/legal") ||
            window.location.href?.includes("/taketest") ||
            window.location.href?.includes("/take-test") ||
            window.location.href?.includes("/completedPublicAssessment") ||
            window.location.href?.includes("/fellowship-program") ||
            window.location.href?.includes("/techruit");
          const token = cookies.get("userToken");
          if (
            !token &&
            !isPublicAccessRoute &&
            sessionStorage.getItem("viewPkgWithoutSignIn") === "false"
          ) {
            window.location.href = "/login";
          }
        }
      } catch {}
    }
    getData();

    if (headerState.state !== loggedInReducer.state) {
      dispatch(handleLoggedIn(headerState));
    }

    // const sessionIsLoggedIn = JSON.parse(sessionStorage.getItem("isLoggedIn"));
    // if (sessionIsLoggedIn?.state) {
    //   dispatch(handleLoggedIn(sessionIsLoggedIn));
    // }
  }, []);

  return (
    <React.StrictMode>
      {(partnerUserAuthenticationCompleted ||
        practiceSSOUserAuthenticationCompleted) && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{
            width: "100%",
            height: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </Grid>
      )}
      {initialCheckCompleted && (
        <>
          {process.env.NODE_ENV !== "development" && (
            <IdleTimer
              ref={(ref) => {
                idleTimer.current = ref;
              }}
              timeout={1000 * 60 * 30}
              onIdle={handleOnIdle}
              debounce={250}
            />
          )}
          <Router>
            <Switch>
              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={uid(idx)}
                  isAuthProtected={false}
                  exact
                />
              ))}
              <Route path="*" exact>
                <Page404 />
              </Route>
            </Switch>
          </Router>
        </>
      )}
    </React.StrictMode>
  );
}

export default AppNew;
